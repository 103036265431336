<template>
    <div>
        <div class="ciascClass">
            <el-dialog :visible.sync="centerDialogVisible" align-center :close-on-click-modal="false"
                :close-on-press-escape="false" @close="close">
                <div class="kjzxcoClass">
                    <div class="kzxciClass"></div>
                    <img class="oiqwfoiClass" src="@/static/headPage/logo.png" alt="">
                    <img class="guaziClass" src="@/static/headPage/guanbi.png" alt=""
                        @click="$store.dispatch('centerDialogVisibleFunction', false)">
                    <div class="oijasClass">
                        <span class="span1" :class="{ spanClick: tabsValue == 'YZM' }" @click="tabsValue = 'YZM'">
                            验证码登录
                        </span>
                        <span class="span2" :class="{ spanClick: tabsValue == 'MIM' }" @click="tabsValue = 'MIM'">
                            密码登录
                        </span>
                    </div>
                    <el-form ref="ruleFormRef" :model="ruleForm" class="fosoackClass">
                        <el-form-item prop="mobile" key="mobile" :rules="[{ required: true, message: '中国大陆手机号不能为空', trigger: 'blur' }, {
                            validator: (rule, value, callback) => {
                                if (!/^1\d{10}$/.test(value)) callback('手机号格式错误')
                                else callback()
                            }
                        }]">
                            <div class="kjzxcoaClass">
                                <div class="lkzxcoClass">
                                    +86
                                </div>
                                <div class="oiuqfwClass">

                                </div>
                                <el-input class="iuzxcoalClass" clearable v-model="ruleForm.mobile"
                                    placeholder="请输入中国大陆手机号" />
                            </div>
                        </el-form-item>
                        <template v-if="tabsValue == 'YZM'">
                            <el-form-item class="iascoClass" prop="smsCode" key="smsCode"
                                :rules="{ required: true, message: '验证码不能为空', trigger: 'blur' }">
                                <div class="oiuzxcoClass">
                                    <el-input class="inputClass" clearable v-model="ruleForm.smsCode"
                                        placeholder="请输入您的验证码" />
                                    <div class="oihzxcClass"></div>
                                    <div class="oizxclClass">
                                        <template v-if="!yzmFlg">
                                            <span class="span" @click="huoqvClick">
                                                获取验证码
                                            </span>
                                        </template>
                                        <template v-else>
                                            <div class="oascClass">
                                                <el-statistic class="oihasodiClass" ref="statistic" @finish="finish"
                                                    format="ss" :value="value1" time-indices>
                                                </el-statistic>
                                                <span class="span1">S</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </el-form-item>
                        </template>
                        <template v-else-if="tabsValue == 'MIM'">
                            <el-form-item class="oiuasclClass" prop="userPassword" key="userPassword"
                                :rules="{ required: true, message: '密码不能为空', trigger: 'blur' }">
                                <div class="uoiasclkjClass">
                                    <el-input class="inputClass" clearable :type="eyeShow ? 'password' : 'text'"
                                        v-model="ruleForm.userPassword" placeholder="请输入您的密码" />
                                    <div class="oiqkasdClass" @click="eyeShow = !eyeShow">
                                        <i class="el-icon-view"></i>
                                        <template v-if="eyeShow">
                                            <div class="mzxcias"></div>
                                        </template>
                                    </div>
                                </div>
                            </el-form-item>
                        </template>
                        <el-form-item>
                            <el-button :loading="butonLoading" class="oiuzxcClass" @click="submitForm">登录</el-button>
                        </el-form-item>
                    </el-form>
                    <div class="ouzxcClass">
                        <span class="span1" @click="miazsClick('/registrationPage?name=M')">
                            忘记密码
                        </span>
                        <span class="span2">

                        </span>
                        <span class="span3" @click="miazsClick('/registrationPage?name=Z')">
                            注册
                        </span>
                    </div>
                    <div class="kjadvlClass">
                        <span class="span">
                            登录或注册即代表同意
                            <span class="span1" @click="show = true; concat = '用户协议'">
                                用户协议
                            </span>
                            和
                            <span class="span2" @click="show = true; concat = '隐私政策'">
                                隐私政策
                            </span>
                        </span>
                    </div>
                </div>
            </el-dialog>
        </div>
        <ProtocolPolicy :value.sync="show" :concat="concat"></ProtocolPolicy>
    </div>
</template>
<script>
import { loginloginByPassword, homequeryCompanyInfo } from "@/api/LoginPopUp";
import { loginsendCode } from "@/api/registrationPage";
import { Message } from 'element-ui';
import ProtocolPolicy from "@/components/ProtocolPolicy/index.vue";

export default {
    components: {
        ProtocolPolicy
    },
    computed: {
        centerDialogVisible() {
            return this.$store.state.centerDialogVisible
        },
        pathUrl() {
            return this.$store.state.pathUrl
        },
    },
    data() {
        return {
            show: false,
            concat: "",
            eyeShow: true,
            tabsValue: "YZM",
            yzmFlg: false,
            value1: "",
            ruleForm: {
                mobile: null,
                smsCode: null,
                userPassword: null,
            },
            butonLoading: false
        }
    },
    methods: {
        miazsClick(path) {
            this.$store.dispatch('centerDialogVisibleFunction', false)
            let vafls = false
            if (this.$route.name == 'RegistrationPage') vafls = true
            this.$router.push(path).then(() => {
                if (vafls)
                    this.$router.go(0)
            })
        },
        close() {
            this.yzmFlg = false
            this.$refs.ruleFormRef.resetFields()
        },
        finish() {
            this.yzmFlg = false
        },
        huoqvClick() {
            if (!this.$refs.ruleFormRef) return
            this.$refs.ruleFormRef.validateField("mobile", (valid, fields) => {
                if (valid) return
                loginsendCode({ userMobile: this.ruleForm.mobile }).then(res => {
                    this.yzmFlg = true
                    this.value1 = Date.now() + 1000 * 60
                })
            })
        },
        submitForm() {
            if (!this.$refs.ruleFormRef) return
            this.$refs.ruleFormRef.validate((valid, fields) => {
                if (valid) {
                    this.butonLoading = true
                    loginloginByPassword({
                        loginType: this.tabsValue == 'YZM' ? 1 : 2,
                        ...this.ruleForm
                    }).then(res => {
                        this.$store.dispatch('centerDialogVisibleFunction', false)
                        localStorage.setItem("TOKEN", res?.data?.token)
                        localStorage.setItem("SINGNKEY", res?.data?.signKey)
                        Message({
                            message: '登陆成功',
                            type: 'success',
                        })
                        const pathTo = this.pathUrl && ['Index', 'CreditServices'].includes(this.$route.name)
                        homequeryCompanyInfo().then(res => {
                            this.$store.dispatch('userInfoFunction', {
                                user: '1',
                                ...res?.data || {},
                                pathTo
                            })
                        }).finally(() => setTimeout(() => {
                            if (pathTo) return this.$router.go()
                            // else router.go()
                            this.$router.push('/personalCenter')
                        }, 500))
                    }).finally(() => {
                        this.butonLoading = false
                    })
                } else {
                    console.error('error submit!', fields)
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.ciascClass {
    :deep() {
        .el-dialog {
            width: auto;
            background: none;
            padding: 0;
            box-shadow: none;

            .el-dialog__header {
                display: none;
            }

            .el-dialog__body {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.kjzxcoClass {
    width: 420px;
    height: 498px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    position: relative;

    .kzxciClass {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 82px;
        background: linear-gradient(90deg, rgba(255, 0, 0, 0.05) 0%, rgba(234, 48, 48, 0.18) 100%);
    }

    .oiqwfoiClass {
        margin: 0 auto;
        margin-top: 19px;
        width: 180px;
        height: 44px;
    }

    .guaziClass {
        cursor: pointer;
        position: absolute;
        width: 16.9px;
        height: 16.9px;
        top: 15px;
        right: 18px;
    }

    .oijasClass {
        margin-top: 51px;
        display: flex;
        justify-content: center;

        @mixin ljzxc {
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            cursor: pointer;
        }

        .span1 {
            @include ljzxc();
        }

        .span2 {
            @include ljzxc();
            margin-left: 68px;
        }

        .spanClick {
            color: #B92B30;
        }
    }

    .fosoackClass {
        margin-top: 29px;

        :deep() {
            // .el-input__wrapper {
            //     box-shadow: none !important;
            //     padding: 0;

            .el-input__inner {
                font-size: 16px;
                color: #000000;
                padding: 0 !important;
                height: 100%;
                line-height: normal;
                border: none;
            }

            .el-input__inner::placeholder {
                opacity: 1 !important;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #999999;
            }

            .el-input__icon {
                width: auto;
            }

            .el-input__suffix {
                right: 0;
            }

            // }
            .el-form-item__error {
                top: 55px;
                left: 35px;
            }
        }

        @mixin lkzxc {
            margin: 0 auto;
            width: 366px;
            height: 53px;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
        }

        .el-form-item {
            margin: 0;
        }

        .kjzxcoaClass {
            @include lkzxc();

            .lkzxcoClass {
                margin-left: 12px;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 16px;
                color: #000000;
            }

            .oiuqfwClass {
                width: 1px;
                height: 20px;
                background: #E6E6E6;
                border-radius: 0px 0px 0px 0px;
                margin-left: 12px;
                margin-right: 18px;
            }

            .iuzxcoalClass {
                flex: 1;
                margin-right: 10px;
            }
        }

        .iascoClass {
            margin-top: 24px;

            .oiuzxcoClass {
                @include lkzxc();

                .inputClass {
                    margin-left: 12px;
                    flex: 1;
                }

                :deep() {
                    .el-statistic__number {
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 16px;
                        color: #898989;
                    }
                }

                .oihzxcClass {
                    margin: 0 14px;
                    width: 1px;
                    height: 20px;
                    background: #E6E6E6;
                    border-radius: 0px 0px 0px 0px;
                }

                .oizxclClass {
                    margin-right: 18px;
                    width: 80px;
                    text-align: center;

                    .span {
                        cursor: pointer;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 16px;
                        color: #B92B30;
                    }

                    .oascClass {
                        display: flex;
                        align-items: baseline;
                        justify-content: center;

                        :deep() {
                            .el-statistic {
                                width: auto;
                            }

                            .number {
                                font-size: 16px;
                                color: #898989;
                            }
                        }

                        .span1 {
                            margin-left: 2px;
                            font-size: 14px;
                            color: #898989;
                        }
                    }
                }
            }
        }

        .oiuasclClass {
            margin-top: 24px;

            .uoiasclkjClass {
                @include lkzxc();

                .inputClass {
                    margin-left: 12px;
                    margin-right: 10px;
                }
            }

            .oiqkasdClass {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
                color: #898989;
                position: relative;
                opacity: 1;
                .el-icon-view{
                    font-size: 20px;
                }
                .mzxcias{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 2px;
                    background-color: #898989;
                    top: 50%;
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }
    }

    .oiuzxcClass {
        margin: 0 auto;
        margin-top: 29px;
        display: block;
        width: 366px;
        height: 53px;
        background: #B92B30;
        border-radius: 100px 100px 100px 100px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
    }

    .oiuzxcClass:active {
        border-color: transparent;
    }

    .ouzxcClass {
        margin-top: 17px;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;

        .span2 {
            margin: 0 10px;
            display: inline-block;
            width: 1px;
            height: 12px;
            background: #D9D9D9;
            border-radius: 0px 0px 0px 0px;
        }

        .span1,
        .span3 {
            cursor: pointer;
        }
    }

    .kjadvlClass {
        margin-top: 24px;
        text-align: center;

        .span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #999999;

            .span1,
            .span2 {
                color: #B92B30;
                cursor: pointer;
            }
        }
    }
}
</style>