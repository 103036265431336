import http from "@/utils/request";

// 账号密码登录
export const loginloginByPassword = data => {
    return http.request({
        url: '/api/login/loginByPassword',
        method: 'post',
        data
    })
}

// 查看企业信息详情
export const homequeryCompanyInfo = data => {
    return http.request({
        url: '/api/home/queryCompanyInfo',
        method: 'post',
        data
    })
}

//  用户协议，隐私政策
export const homeprotocolPolicy = data => {
    return http.request({
        url: '/api/home/protocolPolicy',
        method: 'post',
        data
    })
}
