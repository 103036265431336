import http from "@/utils/request";

// 公告通知
export const noticenoticeList = data => {
    return http.request({
        url: '/api/notice/noticeList',
        method: 'post',
        data
    })
}

// 政策法规
export const policynoticeList = data => {
    return http.request({
        url: '/api/policy/noticeList',
        method: 'post',
        data
    })
}

// 公告通知详情
export const noticenoticeInfo = data => {
    return http.request({
        url: '/api/notice/noticeInfo',
        method: 'post',
        data
    })
}

// 政策法规详情
export const policynoticeInfo = data => {
    return http.request({
        url: '/api/policy/noticeInfo',
        method: 'post',
        data
    })
}
