import http from "@/utils/request";

// 首页轮播图列表
export const homebannerList = data => {
  return http.request({
    url: '/api/home/bannerList',
    method: 'post',
    data
  })
}

// 首页信息
export const homehomeInfo = data => {
  return http.request({
    url: '/api/home/homeInfo',
    method: 'post',
    data
  })
}

// 保函种类
export const creditproductproductTypeList = params => {
  return http.request({
    url: '/api/creditproduct/productTypeList',
    method: 'get',
    params
  })
}
