<template>
    <div class="enterpriseInformationClass">
        <el-dialog :visible.sync="loading" align-center :close-on-press-escape="false" @close="$emit('update:show', false)">
            <div class="qjzuscClass">
                <div class="rjzxckClass">
                    请先完善企业信息后，进入信用报告
                </div>
                <div class="ekjzxcClass">
                    注册信息
                </div>
                <el-form ref="ruleFormRef" :model="ruleForm" class="ruleFormRefClass">
                    <el-form-item label="企业名称" prop="companyName" key="companyName"
                        :rules="{ required: true, message: '请输入企业名称', trigger: 'blur' }">
                        <div class="nmzxcoClass">
                            <div class="tzxckjClass">
                                <div class="yzkjxcClass">
                                    <div class="ukjzxcClass">
                                        <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称" />
                                    </div>
                                    <img @click="ruleForm.companyName = ''" class="izxckClass"
                                        src="@/static/enterpriseInformation/osuahj.png" alt="">
                                </div>
                            </div>
                            <el-button class="chauscClass" :loading="button2Loading" @click="chabutonClick">查 询</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item style="width: 50%;">
                    </el-form-item>

                    <el-form-item label="法定代表人" prop="legalPersonName" key="legalPersonName"
                        :rules="{ required: true, message: '请输入法人姓名', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.legalPersonName" placeholder="请输入法人姓名" />
                                </div>
                                <img @click="ruleForm.legalPersonName = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册地址" prop="registeredAddress" key="registeredAddress"
                        :rules="{ required: true, message: '请输入注册地址', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.registeredAddress" placeholder="请输入注册地址" />
                                </div>
                                <img @click="ruleForm.registeredAddress = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="法定身份号" prop="legalPersonIdNumber" key="legalPersonIdNumber"
                        :rules="{ required: true, message: '请输入法人身份证号', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.legalPersonIdNumber" placeholder="请输入法人身份证号" />
                                </div>
                                <img @click="ruleForm.legalPersonIdNumber = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="经营地址" prop="businessAddress" key="businessAddress"
                        :rules="{ required: true, message: '请输入经营地址', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.businessAddress" placeholder="请输入经营地址" />
                                </div>
                                <img @click="ruleForm.businessAddress = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="成立时间" prop="establishTime" key="establishTime"
                        :rules="{ required: true, message: '请点击选择成立时间', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.establishTime" placeholder="请点击选择成立时间" />
                                    <el-date-picker value-format="YYYY-MM-DD" class="hzlkxcClass"
                                        v-model="ruleForm.establishTime" type="date" placeholder="" size="default" />
                                </div>
                                <img @click="ruleForm.establishTime = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="仓储地址" prop="warehouseAddress" key="warehouseAddress"
                        :rules="{ required: true, message: '请输入仓储地址', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.warehouseAddress" placeholder="请输入仓储地址" />
                                </div>
                                <img @click="ruleForm.warehouseAddress = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="经济类型" prop="economyType" key="economyType"
                        :rules="{ required: true, message: '请输入经济类型', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.economyType" placeholder="请输入经济类型" />
                                </div>
                                <img @click="ruleForm.economyType = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="creditCode" key="creditCode"
                        :rules="{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.creditCode" placeholder="请输入统一社会信用代码" />
                                </div>
                                <img @click="ruleForm.creditCode = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册资本" prop="registerCapital" key="registerCapital"
                        :rules="{ required: true, message: '请输入注册资本金额', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input type="number" v-model="ruleForm.registerCapital" placeholder="请输入注册资本金额" />
                                </div>
                                <img @click="ruleForm.registerCapital = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                            <div class="unmzxciClass">
                                万人民币
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="实收资本" prop="realCapital" key="realCapital"
                        :rules="{ required: true, message: '请输入实收资本金额', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input type="number" v-model="ruleForm.realCapital" placeholder="请输入实收资本金额" />
                                </div>
                                <img @click="ruleForm.realCapital = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                            <div class="unmzxciClass">
                                万人民币
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="经营范围" prop="businessScope" key="businessScope" class="izxcClass"
                        :rules="{ required: true, message: '请输入经营范围', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input type="textarea" v-model="ruleForm.businessScope" placeholder="请输入经营范围" />
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <div class="okjzxcClass" style="grid-column: 1/-1;">
                        通讯信息
                    </div>
                    <el-form-item label="联系人" prop="linkName" key="linkName"
                        :rules="{ required: true, message: '请输入联系人', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.linkName" placeholder="请输入联系人" />
                                </div>
                                <img @click="ruleForm.linkName = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="linkMobile" key="linkMobile"
                        :rules="{ required: true, message: '请输入联系电话', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.linkMobile" placeholder="请输入联系电话" />
                                </div>
                                <img @click="ruleForm.linkMobile = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="通讯地址" prop="mailingAddress" key="mailingAddress"
                        :rules="{ required: true, message: '请输入通讯地址', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.mailingAddress" placeholder="请输入通讯地址" />
                                </div>
                                <img @click="ruleForm.mailingAddress = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="邮编" prop="postalCode" key="postalCode"
                        :rules="{ required: false, message: '请输入邮编', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.postalCode" placeholder="请输入邮编" />
                                </div>
                                <img @click="ruleForm.postalCode = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="email" key="email"
                        :rules="{ required: false, message: '请输入联系邮箱', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.email" placeholder="请输入联系邮箱" />
                                </div>
                                <img @click="ruleForm.email = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="传真" prop="fax" key="fax"
                        :rules="{ required: false, message: '请输入传真', trigger: 'blur' }">
                        <div class="tzxckjClass">
                            <div class="yzkjxcClass">
                                <div class="ukjzxcClass">
                                    <el-input v-model="ruleForm.fax" placeholder="请输入传真" />
                                </div>
                                <img @click="ruleForm.fax = ''" class="izxckClass"
                                    src="@/static/enterpriseInformation/osuahj.png" alt="">
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="wnmzxckClass">
                    <el-button class="akjzxcClass" :loading="buttonLoading" @click="submitForm">保存</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import {
    companyfirstUpdateEnterpriseInfo,
    loginselectCompanyInfo
} from '@/api/company'
import { Message } from 'element-ui';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        value: {
            handler(val) {
                Object.assign(this.ruleForm, val)
            }
        },
        show: {
            handler(val) {
                this.loading = val
            }
        }
    },
    data() {
        return {
            loading: false,
            button2Loading: false,
            buttonLoading: false,
            ruleForm: {
                companyName: null, // 企业名称
                legalPersonName: null, // 法定代表人
                registeredAddress: null, // 注册地址
                legalPersonIdNumber: null, // 法定身份号
                businessAddress: null, // 经营地址
                establishTime: null, // 成立时间
                warehouseAddress: null, // 仓储地址
                economyType: null, // 经济类型
                creditCode: null, // 统一社会信用代码
                registerCapital: null, // 注册资本
                realCapital: null, // 实收资本
                businessScope: null, // 经营范围
                linkName: null, // 联系人
                linkMobile: null, // 联系电话
                mailingAddress: null, // 通讯地址
                postalCode: null, // 邮编
                email: null, // 联系邮箱
                fax: null, // 传真
            }
        }
    },
    methods: {
        chabutonClick() {
            this.button2Loading = true
            loginselectCompanyInfo({
                keyWord: this.ruleForm.companyName
            }).then(res => {
                Object.assign(this.ruleForm, res?.data || {})
            }).finally(() => {
                this.button2Loading = false
            })
        },
        submitForm() {
            if (!this.$refs.ruleFormRef) return
            this.$refs.ruleFormRef.validate((valid, fields) => {
                if (valid) {
                    this.buttonLoading = true
                    companyfirstUpdateEnterpriseInfo(this.ruleForm).then(() => {
                        Message({
                            message: '保存成功',
                            type: 'success',
                        });
                        // this.show = false
                        this.$emit('update:show', false)
                    }).finally(() => {
                        this.buttonLoading = false
                    })
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
:deep() {
    .el-dialog__wrapper {
        display: flex;
    }

    .el-dialog {
        margin-top: auto !important;
        width: auto;
        background: none;
        padding: 0;
        box-shadow: none;

        .el-dialog__header {
            display: none;
        }


        // .el-dialog__body {
        //     display: flex;
        //     justify-content: center;
        // }
    }
}

.qjzuscClass {
    width: 950px;
    // height: 882px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;

    .rjzxckClass {
        height: 54px;
        background: #B92B30;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ekjzxcClass {
        margin-top: 25px;
        margin-left: 22px;
        margin-right: 22px;
        height: 45px;
        padding-left: 12px;
        background: #FFEDEF;
        font-weight: bold;
        font-size: 16px;
        color: #B92B30;
        display: flex;
        align-items: center;
    }



    .ruleFormRefClass {
        margin-top: 26px;
        margin-left: 27px;
        margin-right: 22px;
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            margin-bottom: 16px;
            justify-content: flex-end;
            display: flex;

            :deep() {
                .el-form-item__label {
                    padding-right: 12px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    flex: 1;
                    white-space: nowrap;
                    min-width: 90px;
                }

                .el-form-item__label:before {
                    margin: 0 !important;
                    color: #B92B30 !important;
                }

                .el-form-item__content {
                    line-height: 0;
                    flex: none;
                    align-items: center;
                    display: flex;
                }

                .el-form-item__error {
                    line-height: normal;
                    padding-top: 0;
                    left: 0;
                }

             
                .el-input__inner {
                    height: 100%;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    padding: 0;
                    height: auto;
                    line-height: normal;
                    background-color: transparent;
                    border: none;
                }

                .el-input__inner::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    color: #999999;
                }


                .el-textarea {
                    height: 100%;
                    background-color: transparent;
                }

                .el-textarea__inner {
                    border: none;
                    padding: 0;
                    resize: none;
                    height: 100%;
                    box-shadow: none;
                    background-color: transparent;
                    font-size: 14px;
                    color: #333333;
                    font-family: initial;
                }

                .el-textarea__inner::placeholder {
                    font-size: 14px;
                    color: #999999;
                }


            }
        }

        .el-form-item:nth-of-type(even) {
            :deep() {
                .el-form-item__label {
                    min-width: 173px;
                }
            }
        }


        .izxcClass {
            grid-column: 1/-1;

            .tzxckjClass {
                width: 812px;
                height: 108px;
                border: 1px solid #E6E6E6;
                align-items: flex-start;

                .yzkjxcClass {
                    height: 100%;

                    .ukjzxcClass {
                        height: 100%;

                        :deep() {
                            .el-textarea__inner {
                                padding-top: 5px;
                                padding-bottom: 5px;
                            }

                        }
                    }
                }

            }
        }

        .nmzxcoClass {
            position: relative;
            width: 100%;


            .chauscClass {
                position: absolute;
                top: 0;
                right: -73px;
                width: 73px;
                height: 30px;
                background: #B92B30;
                font-size: 14px;
                color: #FFFFFF;
                border-color: #B92B30;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .tzxckjClass {
            width: 319px;
            display: flex;
            align-items: center;
            height: 30px;
            background: #F9F9F9;
            border: 1px solid #E6E6E6;

            .yzkjxcClass {
                width: 100%;
                display: flex;
                align-items: center;
                flex: 1;

                .ukjzxcClass {
                    flex: 1;
                    margin-left: 12px;
                    position: relative;

                    :deep() {
                        .hzlkxcClass {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                }

                .izxckClass {
                    width: 16px;
                    height: 16px;
                    margin: 8px;
                    cursor: pointer;
                    opacity: 0;
                    transition: .3s;
                }
            }

            .yzkjxcClass:hover {
                .izxckClass {
                    opacity: 1;
                }
            }

            .unmzxciClass {
                padding-left: 17px;
                padding-right: 16px;
                height: 100%;
                background: #EEEEEE;
                font-weight: 400;
                font-size: 14px;
                color: #000000;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .okjzxcClass {
            width: 100%;
            margin-top: 24px;
            margin-bottom: 21px;
            height: 45px;
            padding-left: 12px;
            background: #FFEDEF;
            font-weight: bold;
            font-size: 16px;
            color: #B92B30;
            display: flex;
            align-items: center;
        }
    }

    .wnmzxckClass {
        margin-top: 47px;
        display: flex;
        justify-content: flex-end;

        .akjzxcClass {
            margin-right: 25px;
            margin-bottom: 38px;
            width: 84px;
            height: 40px;
            background: #B92B30;
            border-radius: 5px 5px 5px 5px;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
}
</style>