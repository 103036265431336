<template>
    <div class="isjxuClass">
        <div class="ksuascyClass">
            <el-carousel ref="elcarouselRef" class="elCarouselClass" height="350px" arrow="never" indicator-position="none">
                <el-carousel-item v-for="(item, index) in homebannerListValue" :key="item.id + index">
                    <img class="img" :src="item.coverImage" alt="">
                </el-carousel-item>
            </el-carousel>
            <div class="kjsaicxzlClass">
                <img class="img1" src="@/static/home/zuo.png" alt="" @click="$refs.elcarouselRef.prev()">
                <img class="img2" src="@/static/home/you.png" alt="" @click="$refs.elcarouselRef.next()">
            </div>
        </div>
        <!-- 协会介绍 -->
        <img class="kzxcoasClass" :src="homehomeInfoValue.associationIntroduction" alt="">
        <!-- 信用服务 -->
        <div class="voiasClass_1">
            <img class="lkjzxciImgClass" src="@/static/home/baohabjt.png" alt="">
            <div class="oiasclClass">
                <div class="jxcuascyClass">
                    <div class="jciuaClass">

                    </div>
                    <div class="cuasiClass">
                        信用服务
                    </div>
                    <div class="cjikasoClass">

                    </div>
                </div>
                <div class="oiuzxclClass">
                    <template v-for="item in baohanList">
                        <div class="oizxclaClass" :key="item.id">
                            <img class="img" :src="item.insuranceImage" alt="">
                            <div class="lzxcoaClass">
                                {{ item.insuranceType }}
                            </div>
                            <div class="kjzxclClass">
                                {{ item.represent }}
                            </div>
                            <el-button :loading="item.butonLoading" class="lzxcopaClass" @click="buxtaClick(item)">
                                {{ item.butTitle }}
                            </el-button>
                        </div>
                    </template>

                </div>
            </div>
        </div>
        <div class="kjzxcuClass">
            <img class="casiClass" src="@/static/home/pxbjt.png" alt="">
            <div class="kjzxciClass">
                <div class="jxcuascyClass">
                    <div class="jciuaClass">

                    </div>
                    <div class="cuasiClass">
                        培训认定
                    </div>
                    <div class="cjikasoClass">

                    </div>
                </div>
                <div class="ciasiClass">
                    培训报名、考试认证入口
                </div>
                <div class="kjxciaClass">
                    <img class="zxckjasClass" src="@/static/home/pximg1.png" alt="" @click="butonClick(1)">
                    <div class="kjasdoClass">
                        <img class="img1" src="@/static/home/pximg2.png" alt="" @click="butonClick(2)">
                        <img class="img2" src="@/static/home/pximg3.png" alt="" @click="butonClick(3)">
                        <img class="img3" src="@/static/home/pximg4.png" alt="" @click="butonClick(4)">
                        <img class="img4" src="@/static/home/pximg5.png" alt="" @click="butonClick(5)">
                    </div>
                </div>
            </div>
        </div>
        <!-- 最新动态 -->
        <div class="kjzxcoClass" ref="targetElement">
            <div class="jxcuascyClass">
                <div class="jciuaClass">

                </div>
                <div class="cuasiClass">
                    最新动态
                </div>
                <div class="cjikasoClass">

                </div>
            </div>
            <!-- <div class="ciasiClass">
                查看最新保函新闻动态
            </div> -->
            <div class="kzxciClass">
                <template v-for="(item, index) in dontList">
                    <div class="asciuClass" :key="item.id + index"
                        @click="$router.push(`/notificationAnnouncementDetails?id=${item.id}&indx=1`)">
                        <img class="img" :src="item.coverImage" alt="">
                        <div class="kzxciuClass">
                            <div class="iuascoClass">
                                <span class="span1">
                                    {{ item.articleTitle }}
                                </span>
                                <span class="span2" v-if="item.labelName">
                                    {{ item.labelName }}
                                </span>
                            </div>
                            <div class="zxtcasiClass">
                                {{ limitNumber(item.summaryContent, 4.5) }}
                            </div>
                        </div>
                    </div>
                </template>
                <el-button class="kjascoiClass" @click="$router.push('/notificationAnnouncement')">查看更多</el-button>
            </div>
        </div>
        <div class="hjzxcuiClass">
            <div class="jxcuascyClass">
                <div class="jciuaClass">

                </div>
                <div class="cuasiClass">
                    友情链接
                </div>
                <div class="cjikasoClass">

                </div>
            </div>
            <div class="nmzxckClass">
                <img class="img" src="@/static/home/imgsash1.png" alt="" @click="gunjiaClick('https://www.cbirc.gov.cn/')">
                <img class="img" src="@/static/home/imgsash2.png" alt="" @click="gunjiaClick('http://www.csrc.gov.cn/')">
                <img class="img" src="@/static/home/imgsash3.png" alt="" @click="gunjiaClick('https://www.samr.gov.cn/')">
                <img class="img" src="@/static/home/imgsash4.png" alt="" @click="gunjiaClick('https://www.ndrc.gov.cn/')">
            </div>
        </div>
        <div class="oihzxcClass">
            <div class="klzxcoias">
                <el-popover placement="bottom-start" trigger="hover" :teleported="false" :hide-after="0">
                    <template #reference>
                        <!-- 客服 -->
                        <img class="img" src="@/static/home/kefu.png" alt="">
                    </template>
                    <img class="mzxcClass" src="@/static/home/kefu_1.png" alt="">
                </el-popover>
            </div>
        </div>
        <EnterpriseInformation :show.sync="show" :value="getCompanyInfoValue"></EnterpriseInformation>

    </div>
</template>
<script>
import { homebannerList, homehomeInfo, creditproductproductTypeList } from "@/api/home";
import { noticenoticeList } from "@/api/notificationAnnouncement";
import EnterpriseInformation from "@/components/EnterpriseInformation/index.vue";

import {
    getCompanyInfo,
} from '@/api/company'
import {
    Message
} from 'element-ui'
import img1 from "@/static/home/xy_img1.png";
import img2 from "@/static/home/xy_img2.png";
import img3 from "@/static/home/img3.png";
import img4 from "@/static/home/xy_img4.png";
import img5 from "@/static/home/xy_img5.png";
export default {
    name: "Index",
    components: {
        EnterpriseInformation
    },
    activated() {
        this.init()
        if (this.pathUrl == 'Index') {
            this.$store.dispatch('pathUrlFunction', "")
            setTimeout(() => {
                if (this.$refs.targetElement) {
                    this.$refs.targetElement.scrollIntoView(true);
                }
            }, 100);
        }
    },
    created() {
        if (this.userInfo.pathTo) {
            this.$store.dispatch('userInfoFunction', {
                pathTo: ""
            })
            this.getCompanyInfoApi().then(res => {
                Object.assign(this.getCompanyInfoValue, res?.data || {}, {
                    infoFlg: true
                })
                if (String(this.getCompanyInfoValue?.isPerfect) == 0) this.show = true
            })
        }
    },
    computed: {
        pathUrl() {
            return this.$store.state.pathUrl
        },
        userInfo() {
            return this.$store.state.userInfo
        },
    },
    data() {
        return {
            getCompanyInfoValue: {},
            show: false,
            baohanList: [
                {
                    id: "1",
                    insuranceImage: img1,
                    insuranceType: "一站式信用保函申请及验真",
                    represent: "手续简单、出函快速，支持多机构保函办理",
                    path: "/guaranteeList",
                    butTitle: "去申请"
                },
                {
                    id: "2",
                    insuranceImage: img2,
                    insuranceType: "信用报告",
                    represent: "展示信用状况的官方文件",
                    isFlg: true,
                    butTitle: "去申请",
                    butonLoading: false
                },
                {
                    id: "3",
                    insuranceImage: img3,
                    insuranceType: "信用修复",
                    represent: "帮助企业摆脱信用不良记录",
                    isFlg: false,
                    butTitle: "去修复"
                },
                {
                    id: "4",
                    insuranceImage: img4,
                    insuranceType: "信用融资",
                    represent: "为企业提供多样化的融资途径",
                    isFlg: false,
                    butTitle: "去申请"
                },
                {
                    id: "5",
                    insuranceImage: img5,
                    insuranceType: "信用管理",
                    represent: "涵盖从信用评估到信用修复等多个方面",
                    isFlg: false,
                    butTitle: "去申请"
                }
            ],
            dontList: [],
            noticenoticeListValue: {
                pageNum: 1,
                pageSize: 20
            },
            homehomeInfoValue: {},
            homebannerListValue: [],
            list: [
                {
                    id: 1,
                    img: "/static/home/banner.png",
                    name: "协会性质",
                    title: "沈阳市信用协会（英文译名：Shenyang Credit Association，其缩写为SCA。） 是由政府机关以外的组织和个人自愿联合组成的全市信用行业社会团体。"
                },
                {
                    id: 2,
                    img: "/static/home/banner.png",

                    title: "积极参与沈阳市社会信用体系建设，弘扬诚实守信精神，推动政府、 企业和个人信用体系的建立健全，推动构建守信激励失信惩戒的信用环境，搭建会员服务平台，为打造“信用沈阳”贡献力量。"
                },
                {
                    id: 3,
                    img: "/static/home/banner.png",

                    name: "社会服务-推广信用建设政策",
                    title: "宣传党和国家及沈阳市委、市政府关于信用建设的方针、政策、法律和法规； 为沈阳市社会信用体系建设提供理论和技术支持；促进沈阳地区信用服务行业发展； 推进沈阳市企业和个人信用体系建设；加强与国内外信用组织的沟通与联系；为会员提供优质的信用服务。"
                }
            ]
        }
    },
    methods: {
        buxtaClick(item) {
            if (item.path) this.$router.push(item.path)
            else if (item.isFlg) {
                const callback = (val = {}) => {
                    if (String(val?.isPerfect) == 0) this.show = true
                    else if (String(val?.isPerfect) == 1) this.windowFunction()
                }
                if (this.getCompanyInfoValue.infoFlg) {
                    callback(this.getCompanyInfoValue)
                }
                else {
                    item.butonLoading = true
                    this.getCompanyInfoApi().then(res => {
                        Object.assign(this.getCompanyInfoValue, res?.data || {})
                        callback(res?.data)
                    }).catch(() => {
                        this.$store.dispatch('pathUrlFunction', "/index")
                    }).finally(() => item.butonLoading = false)
                }
            }
            else Message.error('暂未开放')
        },
        butonClick(val) {
            this.$router.push('/trainingCertification?val=' + val)
        },
        windowFunction() {
            window.open('http://www.sysxyxh.com/api/sso/oauth2.0/transfer?clientId=rbO32S5jCwf2wFso&token=' + localStorage.getItem('TOKEN'))
        },
        getCompanyInfoApi() {
            return getCompanyInfo()
        },
        gunjiaClick(path) {
            window.open(path)
        },
        noticenoticeListApi() {
            // 公告通知
            noticenoticeList(this.noticenoticeListValue).then(res => {
                const { list = [] } = res?.data || {}
                this.dontList.length = 0
                this.dontList.unshift(...list)
            })
        },
        homehomeInfoApi() {
            homehomeInfo().then(res => {
                Object.assign(this.homehomeInfoValue, res?.data || {})
            })
        },
        homebannerListApi() {
            homebannerList().then(res => {
                this.homebannerListValue.length = 0
                this.homebannerListValue.unshift(...res?.data || [])
            })
        },
        init() {
            this.homebannerListApi()
            this.homehomeInfoApi()
            this.noticenoticeListApi()
        }
    }
}
</script>

<style lang="scss" scoped>
.isjxuClass {
    $width: 1200px;

    @mixin ellipsis($cum: 3) {
        display: -webkit-box;
        -webkit-line-clamp: $cum;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ksuascyClass {
        position: relative;
        overflow: hidden;

        .elCarouselClass {
            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .kjsaicxzlClass {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 0;
            width: $width;
            display: flex;
            justify-content: space-between;

            .img1,
            .img2 {
                width: 34px;
                height: 34px;
                cursor: pointer;
                margin-top: 16%;
                transform: translateY(-34px);
            }
        }
    }

    @mixin ckjasci {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @mixin iasck {
        width: 14px;
        height: 2px;
        background: #C6C6C6;
    }

    @mixin nbzxci {
        margin: 0 12px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        color: #333333;
    }

    @mixin kjzxci {
        text-align: center;
        margin-top: 2px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
    }

    .kzxcoasClass {
        margin: 0 auto;
        width: 100%;
    }

    .voiasClass_1 {
        margin-top: 49px;
        min-height: 558px;
        padding-bottom: 78px;
        position: relative;

        .lkjzxciImgClass {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .oiasclClass {
            width: $width;
            margin: 0 auto;

            .jxcuascyClass {
                @include ckjasci();
                padding-top: 59px;

                .jciuaClass,
                .cjikasoClass {
                    @include iasck();
                }

                .cuasiClass {
                    @include nbzxci();
                }
            }

            .ciasiClass {
                @include kjzxci();
            }

            .oiuzxclClass {
                margin-top: 38px;
                gap: 27px;
                display: flex;
                justify-content: space-between;
                // display: grid;
                // grid-template-columns: repeat(5, 1fr);
                // justify-items: center;

                .oizxclaClass {
                    width: 225px;
                    min-height: 267px;
                    background: #fff;
                    border-radius: 10px 10px 10px 10px;
                    border: 1px solid #FDD2D6;
                    position: relative;

                    .img {
                        margin: 0 auto;
                        margin-top: 24px;
                        width: 40px;
                        height: 40px;
                    }

                    .lzxcoaClass {
                        margin-top: 12px;
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        text-align: center;
                    }

                    .kjzxclClass {
                        text-align: center;
                        margin-left: 12px;
                        margin-right: 10px;
                        margin-top: 15px;
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;
                    }

                    .lzxcopaClass {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 26px;
                        display: block;
                        width: 152px;
                        height: 40px;
                        background: #B92B30;
                        border-radius: 5px 5px 5px 5px;
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: bold;
                        font-size: 16px;
                        color: #FFFFFF;
                    }

                    .lzxcopaClass:active {
                        border-color: transparent;
                    }

                }

            }
        }
    }


    .kjzxcuClass {
        // margin-top: 48px;
        min-height: 568px;
        position: relative;

        .casiClass {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .kjzxciClass {
            width: $width;
            margin: 0 auto;

            .jxcuascyClass {
                @include ckjasci();

                padding-top: 44px;

                .jciuaClass,
                .cjikasoClass {
                    @include iasck();
                }

                .cuasiClass {
                    @include nbzxci();
                }
            }

            .ciasiClass {
                @include kjzxci();
            }

            .kjxciaClass {
                margin-top: 40px;
                display: flex;
                justify-content: space-between;
                padding-bottom: 87px;

                .zxckjasClass {
                    width: 245px;
                    height: 324px;
                    cursor: pointer;
                }

                .kjasdoClass {
                    flex: 1;
                    margin-left: 23px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    // display: grid;
                    // grid-template-columns: repeat(2, 1fr);
                    // gap: 24px;

                    @mixin kjzxci {
                        width: 454px;
                        height: 150px;
                        cursor: pointer;
                        display: block;
                    }

                    .img1 {
                        @include kjzxci();
                    }

                    .img2 {
                        @include kjzxci();
                    }

                    .img3 {
                        @include kjzxci();
                        margin-top: 24px;
                    }

                    .img4 {
                        @include kjzxci();
                        margin-top: 24px;
                    }
                }
            }
        }
    }

    .kjzxcoClass {
        width: $width;
        margin: 0 auto;
        margin-top: 46px;

        .jxcuascyClass {
            @include ckjasci();

            .jciuaClass,
            .cjikasoClass {
                @include iasck();
            }

            .cuasiClass {
                @include nbzxci();
            }
        }

        .ciasiClass {
            @include kjzxci();
        }

        .kzxciClass {
            margin-top: 48px;

            .asciuClass {
                border-bottom: 1px solid #E6E6E6;
                padding-bottom: 20px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .img {
                    width: 154px;
                    height: 106px;
                    object-fit: cover;
                }

                .kzxciuClass {
                    flex: 1;
                    margin-left: 14px;

                    .iuascoClass {
                        display: flex;
                        align-items: center;

                        .span1 {
                            font-family: Source Han Sans CN, Source Han Sans CN;
                            font-weight: bold;
                            font-size: 18px;
                            color: #333333;
                        }

                        .span2 {
                            display: inline-block;
                            text-align: center;
                            margin-left: 5px;
                            width: 78px;
                            height: 26px;
                            line-height: 26px;
                            background: #B92B30;
                            border-radius: 3px 3px 3px 3px;
                            font-family: Source Han Sans CN, Source Han Sans CN;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                    }

                    .zxtcasiClass {
                        @include ellipsis();
                        margin-top: 8px;
                        font-family: Source Han Sans CN, Source Han Sans CN;
                        font-weight: 400;
                        font-size: 14px;
                        color: #999999;
                    }
                }
            }


            .kjascoiClass {
                display: block;
                margin: 0 auto;
                margin-top: 32px;
                width: 156px;
                height: 40px;
                border-radius: 5px 5px 5px 5px;
                border: 1px solid #B92B30;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: bold;
                font-size: 16px;
                color: #B92B30;
            }

            .kjascoiClass:hover {
                background-color: #b91b2d13;
            }

        }
    }

    .hjzxcuiClass {
        width: $width;
        margin: 0 auto;
        margin-top: 117px;

        .jxcuascyClass {
            @include ckjasci();

            .jciuaClass,
            .cjikasoClass {
                @include iasck();
            }

            .cuasiClass {
                @include nbzxci();
            }
        }

        .ciasiClass {
            @include kjzxci();
        }

        .nmzxckClass {
            margin-top: 48px;
            margin-bottom: 32px;
            // display: grid;
            // grid-template-columns: repeat(4, 1fr);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // gap: 45px;

            .img {
                cursor: pointer;
                width: 266px;
                height: 53px;
            }
        }
    }

    .oihzxcClass {
        position: fixed;
        z-index: 2;
        top: 60vh;
        right: 51px;

        .klzxcoias {
            position: relative;
            width: 90px;
            height: 98px;
            cursor: pointer;


            .img {
                width: 100%;
                height: 100%;
            }


        }
    }
}

.mzxcClass {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -23px;
    width: 162px;
    height: 168px;
}
</style>