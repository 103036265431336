import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/font/font.css"
import VueCropper from 'vue-cropper'

Vue.config.productionTip = false
Vue.use(VueCropper)
Vue.use(ElementUI).mixin({
  methods: {
    limitNumber(txt, num = 1) {
      return String(txt).slice(0, num * 50) + '...'
    },
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
