import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    centerDialogVisible: false,
    userInfo: {
      user: ""
    },
    pathUrl: ""
  },
  getters: {
  },
  mutations: {
    CENTER_DIALOG_VISIBLE(state, value) {
      state.centerDialogVisible = value
    },
    USER_INFO(state, value) {
      if (value?.user === '0') {
        localStorage.removeItem('TOKEN')
        localStorage.removeItem('SINGNKEY')
      }
      Object.assign(state.userInfo, value)
    },
    PATH_URL(state, value) {
      state.pathUrl = value
    }
  },
  actions: {
    centerDialogVisibleFunction(state, value) {
      state.commit('CENTER_DIALOG_VISIBLE', value)
    },
    userInfoFunction(state, value) {
      state.commit('USER_INFO', value)
    },
    pathUrlFunction(state, value) {
      state.commit('PATH_URL', value)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          // 只储存state中的assessmentData
          userInfo: val.userInfo
        }
      }
    })
  ]
})
